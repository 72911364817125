'use client'
import Image from 'next/image'
import Link from 'next/link'

const features = [
  {
    href: 'https://www.ted.com/',
    image: '/assets/featured/ted.png',
  },
  {
    href: 'https://www.yahoo.com/',
    image: '/assets/featured/yahoo.png',
  },
  {
    href: 'https://www.bloomberg.com/',
    image: '/assets/featured/bloomberg.png',
  },
  {
    href: 'https://hackernoon.com/',
    image: '/assets/featured/hackernoon.png',
  },
  {
    href: 'https://www.producthunt.com/',
    image: '/assets/featured/producthunt.png',
  },
  {
    href: 'https://abc.com/',
    image: '/assets/featured/abc.svg',
  },
  {
    href: 'https://www.foxnews.com/',
    image: '/assets/featured/fox.svg',
  },
  {
    href: 'https://www.cbsnews.com/',
    image: '/assets/featured/cbs.svg',
  },
  {
    href: 'https://aitools.inc/tools/sensay',
    image: '/assets/featured/aitools.svg',
  },
]

const Featured = () => {
  return (
    <div className="relative antialiased">
      <h2 className="pb-6 text-center font-quincycf text-3xl font-bold">Featured in</h2>

      <div className="wrapper">
        <div className="marquee">
          <div className="marquee__group__slower">
            {features.map((feature, index) => (
              <Link
                href={feature.href ?? '#'}
                className="h-24 px-10 flex items-center justify-center rounded-xl"
                target="_blank"
                key={index}
              >
                <Image
                  alt={feature.image}
                  width={158}
                  height={48}
                  src={feature.image}
                  className="opacity-70 grayscale"
                />
              </Link>
            ))}
          </div>
          <div aria-hidden className="marquee__group__slower">
            {features.map((feature, index) => (
              <Link
                href={feature.href ?? '#'}
                className="h-24 px-10 flex items-center justify-center rounded-xl"
                target="_blank"
                key={index}
              >
                <Image
                  alt={feature.image}
                  width={158}
                  height={48}
                  src={feature.image}
                  className="opacity-70 grayscale"
                />
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Featured
