import { type RefObject, useEffect, useState } from 'react'

interface UseOnScreenProps {
  ref: RefObject<HTMLElement>
  rootMarginOffset: string
  defaultState: boolean
}

export function useOnScreen({ ref, rootMarginOffset, defaultState }: UseOnScreenProps) {
  const [isIntersecting, setIntersecting] = useState(defaultState)

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting), {
      rootMargin: rootMarginOffset,
    })

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current)
      }
      observer.disconnect()
    }
  }, [ref])

  return isIntersecting
}
