'use client'

import PrimaryButton from '@/components/PrimaryButton'
import StarBg from '@/public/assets/home/art.svg'
import StarFull from '@/public/assets/home/star-full.svg'
import Star from '@/public/assets/star.svg'
import Image from 'next/image'
import Link from 'next/link'
import { type Ref, forwardRef, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

const avatarData = [
  {
    name: 'Vanessa, CHRO',
    imagePath: '/assets/home/girl_2.png',
  },
  {
    name: 'James, CTO',
    imagePath: '/assets/home/guy_2.png',
  },
  {
    name: 'Juliet, CFO',
    imagePath: '/assets/home/girl_1.png',
  },
  {
    name: 'Mark, Lead UI/UX Designer',
    imagePath: '/assets/home/guy_1.png',
  },
]

type HeroAvatarProps = {
  name: string
  imagePath: string
  index: number
  activeIndex: number
}

const HeroAvatar = ({ name, imagePath, index, activeIndex }: HeroAvatarProps) => {
  const isActive = index === activeIndex
  const isNext = index === activeIndex + 1 || (activeIndex === avatarData.length - 1 && index === 0)

  return (
    <div
      className={twMerge(
        'absolute w-full h-full aspect-[443/519] max-w-[443px] transform-gpu opacity-0',
        isActive && 'animate-activate z-20',
        !isActive && 'animate-deactivate z-10',
        !isActive && !isNext && 'animate-deactivate ',
        !isActive && isNext && 'animate-nextActivate',
      )}
    >
      <div className="absolute z-10 flex bottom-[50px] top-[16px] bg-gradient-to-b p-4 from-[#737373]/5 to-white/5 rounded-lg w-full max-w-[443px]">
        <div className="mx-auto w-full mt-[-10px]">
          <StarBg className="fill-primary" />
        </div>
      </div>
      <div className="absolute z-20 bottom-0 right-[-20px] w-full h-full max-w-[383px] aspect-[383/598]">
        <Image
          src={imagePath}
          alt={name}
          width={383}
          height={598}
          className="object-contain absolute right-0 bottom-0 left-0 w-full h-full"
        />
      </div>
      <div className="absolute z-30 bottom-[50px] top-[16px] right-0 border border-white/30 rounded-lg w-full max-w-[443px] flex flex-col p-2">
        <div className="mt-auto bg-black/50 backdrop-blur-md flex flex-col justify-center items-center p-4 rounded-lg">
          <p className="text-white">{name}</p>
          <Link
            className="w-[200px] mx-auto border border-color-[#F8F3E4] rounded-lg text-center py-2 text-[#F8F3E4] mt-3 transition-all hover:bg-[#191829]"
            href="/replicas/sensay"
          >
            View Replicas
          </Link>
        </div>
      </div>
    </div>
  )
}

const Hero = forwardRef<HTMLDivElement, object>(function Hero(_, ref: Ref<HTMLDivElement>) {
  const [currentIndex, setCurrentIndex] = useState<number>(0)

  const handleNextAvatar = (e?: React.MouseEvent<HTMLElement>) => {
    if (!e || !(e.target instanceof HTMLAnchorElement)) {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % avatarData.length)
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      handleNextAvatar()
    }, 5000)

    return () => clearInterval(interval)
  }, [])

  return (
    <section
      ref={ref}
      className="relative w-full -mt-16 bg-[#191829] pt-28 md:mt-[-60px] px-4 pb-8 lg:pb-0 overflow-hidden"
      onClick={(e) => handleNextAvatar(e)}
    >
      <div className="relative z-10 mx-auto flex flex-col max-w-6xl lg:flex-row gap-9">
        <div className="flex flex-col justify-center w-full pr-4 order-2 xl:order-1">
          <h1 className="max-w-xl text-[#F8F3E4] text-4xl font-bold md:text-6xl">
            A Second Self,
            <br />A Second Team
          </h1>
          <div className="flex items-center mt-6 ">
            <span className="w-10 mr-4">
              <StarFull className="fill-primary" />
            </span>
            <p className="max-w-xl text-lg tracking-wide text-[#cccccc]">
              Sensay are the human experts in creating lifelike
              <br />
              human replicas to preserve and share knowledge
            </p>
          </div>
          <div className="flex w-full items-center gap-x-6 mt-6">
            <Link href="/me">
              <PrimaryButton
                className="w-full bg-opacity-100 py-1.5 text-base text-white hover:text-primary sm:max-w-xs px-14"
                leftIcon={<Star className="h-5 w-5 fill-current" />}
              >
                Create your Replica
              </PrimaryButton>
            </Link>
          </div>
        </div>
        <div className="ml-auto mr-auto relative w-full max-w-[440px] aspect-[443/519] order-1 lg:order-2 lg:mr-unset">
          {avatarData.map((avatar, index) => (
            <HeroAvatar
              key={avatar.name}
              name={avatar.name}
              imagePath={avatar.imagePath}
              index={index}
              activeIndex={currentIndex}
            />
          ))}
        </div>
      </div>
    </section>
  )
})

export default Hero
