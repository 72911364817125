'use client'

import Header from '@/components/Header'
import { useOnScreen } from '@/hooks/useOnScreen'
import { useRef } from 'react'
import Hero from './Hero'

export default function HeaderHero() {
  const heroRef = useRef<HTMLDivElement>(null)
  const isHeroVisible = useOnScreen({
    ref: heroRef,
    rootMarginOffset: '-20px',
    defaultState: true,
  })

  return (
    <>
      <Header isHeroVisible={isHeroVisible} />
      <Hero ref={heroRef} />
    </>
  )
}
